/** @format */

import React, { useEffect, useRef, useState } from "react";
import { RiCloseLine } from "react-icons/ri";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BiLogOutCircle } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { AdminSidebar, GuardianSidebar, MainSidebar,ResidentSidebar } from "../../assets/Constant/Constant";
import { getObjectUrlFromDownloadUrl, LogOutHandler } from "../../Repository/Apis";
import { userProfile } from "../../store/authSlice";
import "../../CSS/Sidebar.css";

const Sidebar = ({ hamb, setHamb }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const ProfileDetails = useSelector(userProfile);
  const [navItem, setNavItem] = useState([]);
  const activeItemRef = useRef(null);
  const logOut = () => {
    dispatch(LogOutHandler(navigate));
  };
  useEffect(() => {
    if(ProfileDetails?.userType==="Admin"){
      setNavItem(AdminSidebar)
    }else if(ProfileDetails?.userType==="Employee"){
      setNavItem(MainSidebar)
    }else if(ProfileDetails?.userType==="Patient"){
      setNavItem(ResidentSidebar)
    }else if(ProfileDetails?.userType==="Guardian"){
      setNavItem(GuardianSidebar)
    }

  },[ProfileDetails])


  // Scroll the active sidebar item into view on page load or location change
  useEffect(() => {
    if (activeItemRef.current) {
      activeItemRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [location.pathname]);

  return (
    <>
      <aside
        style={{ alignItems: "center", position:"fixed" }}
        className="sm:bg-[rgb(26,159,178)] outer-sidebar"
      >
        {/* Top */}
        <div className="w-full md:hidden relative p-2 mb-4">
          <RiCloseLine
            onClick={() => setHamb(!hamb)}
            className="text-3xl  absolute top-2 sm:hover:rotate-[228deg] transition-transform font-bold right-2 sm:hover:text-[22px] text-[rgb(241,146,46)] cursor-pointer"
          />
        </div>
        {/* Logo */}
        <figure className="flex flex-col items-center">
          <Link to={location} style={{ textDecoration: "none" }}>
            {ProfileDetails?.adminId?.logo||ProfileDetails?.logo ? (
              <img
                style={{
                  // maxWidth: "120px",
                  maxHeight: "80px",
                }}
                src={getObjectUrlFromDownloadUrl(ProfileDetails?.adminId?.logo||ProfileDetails?.logo)}
                alt=""
              />
            ) : (
              <img
              style={{
                // maxWidth: "120px",
                maxHeight: "80px",
              }}
                src="/logo.png"
                alt=""
              />
            )}
          </Link>
        </figure>
        {/* Nav-menu */}
        
        
        <nav className="outer-siderbar-nav">
          <ul>
            {navItem?.map((nav, index) => (
              <li
                key={`nav${index}`}
                ref={location.pathname === nav.link ? activeItemRef : null} 
                onClick={() => navigate(nav.link)}
                className={`menu-item ${location.pathname === nav.link && "active"}`}
              >
                {typeof nav.icon === 'string' ? (
                      <img src={nav.icon} alt="" />
                    ) : (
                     <nav.icon color={"white"} size={40}/>
                    )}
                <div className={`text ${location.pathname === nav.link && "active"}`}>
                  {nav.name}
                </div>
              </li>
            ))}
          </ul>
          <div className="logouticon" >
            <div onClick={() => logOut()} className="icon1 cursor-pointer">
              <BiLogOutCircle className="iconLogout" /> <span>Logout</span>
            </div>
          </div>
        </nav>

        <h5 className="app-verion-text mb-0">App version 1.0</h5>
      </aside>
    </>
  );
};

export default Sidebar;

