import React, { useEffect, useState } from "react";
import { Button, Form, Modal, ModalBody } from "react-bootstrap";
import { getApi, postApi, showNotification } from "../../Repository/Apis";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { BaseUrl } from "../../admin/MainComponent/mainfile";
import { auth } from "../../utils/mainfile";
import axios from "axios";

const StaffFacility = (props) => {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [location, setLocation] = useState("");
    const [isActive, setIsActive] = useState(true);
    const [loading, setLoading] = useState(false);
    const [facilityList, setFacilityList] = useState([]);
    
   const fetchHandler = () => {
      getApi({
        url: `list-facility`,
        setResponse: setFacilityList ,
      });
    };
    useEffect(()=>{
        fetchHandler()
    },[props?.show])
    const resetFields = () => {
      setName("");
      setDescription("");
      setLocation("");
      setIsActive(true);
    };
  
    const submitHandler = () => {
      if (!name.trim() || !description.trim() || !location.trim()) {
        showNotification({
          message: "All fields are required.",
          type: "danger",
        });
        return;
      }
  
      try {
        postApi(setLoading, `admin/add-facility`, {
          name,
          description,
          location,
          isActive,
        });
        resetFields();
        props.onHide();
      } catch (error) {
        const msg = error?.response?.data?.message || "An error occurred";
        showNotification({ message: msg, type: "danger" });
        props.onHide();
      }
    };

    const handleRemoveEmployee = async (id) => {
        try {
          const res = await axios.delete(
            `${BaseUrl}/admin/delete-facility/${id}`,
            auth()
          );  
         
          showNotification({ message: "res.data.message", type: "success" });
          props.onHide();
          // fetchHandler();
        } catch (error) {
          console.error(error);
          // Handle error appropriately
       
          showNotification({ message: "Failed to remove Facility", type: "danger" });
        }
      };
      
  
    return (
      <>
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => {
            resetFields();
            props.onHide();
          }}
        >
          <Modal.Header closeButton>
            <h5 className="fw-bold mb-0">Update Details</h5>
          </Modal.Header>
          <ModalBody>
            <Form.Group className="mb-3">
              <Form.Label className="fw-bold">Name</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter name"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="fw-bold">Description</Form.Label>
              <Form.Control
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter description"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="fw-bold">Location</Form.Label>
              <Form.Control
                type="text"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                placeholder="Enter location"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check
                type="switch"
                id="isActiveSwitch"
                label="Active Status"
                checked={isActive}
                onChange={(e) => setIsActive(e.target.checked)}
              />
            </Form.Group>
             <Form.Group className="mb-3">
                          <Form.Label style={{ width: "100%", fontWeight: "bold" }}>
                          Facility List 
                          </Form.Label>
                          <div>
                            {Array.isArray(facilityList?.data) && facilityList?.data?.length > 0 ? (
                              facilityList?.data?.map((faci, index) => (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      gap: "1rem",
                                      marginBottom: "0.7rem",
                                    }}
                                  >
                                    <Form.Control
                                      className="margin-bottom-2"
                                      key={index}
                                      type="text"
                                      value={`${faci?.location} , ${faci?.description}`}
                                      disabled
                                      readOnly
                                    />
                                    <span onClick={() => handleRemoveEmployee(faci?._id)}>
                                      <RiDeleteBin5Fill
                                        className="mt-2"
                                        style={{ cursor: "pointer", color: "red" }}
                                      />
                                    </span>
                                  </div>
                                </>
                              ))
                            ) : (
                              <p>No Facility Found</p>
                            )}
                          </div>
                        </Form.Group>
          </ModalBody>
          <Modal.Footer className="justify-content-center">
            <Button className="theme-button" onClick={submitHandler}>
              SAVE
            </Button>
            <Button
              className="theme-button-outline"
              onClick={() => {
                resetFields();
                props.onHide();
              }}
            >
              CANCEL
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}

export default StaffFacility