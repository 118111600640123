

export const auth = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
};

export const logout = () => {
  localStorage.removeItem("token");
  window.location.reload();
};

// export const dateConverter = (date) => {
//   if(date===null ||date=== undefined) return "None"
 
//   const dateArray = date?.split("T" || "/")[0]?.split("-")?.reverse();
  
  
//   const reorderedDateArray = [dateArray[1], dateArray[0], dateArray[2]];

//   const formattedDate = reorderedDateArray?.join("-");
  
//   return formattedDate;
// };


// export function formatDateToDDMMYYYY(dateInput) {
//   if (!dateInput) return "";

//   const date = new Date(dateInput);
  
//   if (isNaN(date.getTime())) return "";

//   const day = String(date.getDate()).padStart(2, '0');
//   const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
//   const year = date.getFullYear();

//   return `${day}/${month}/${year}`;
// }