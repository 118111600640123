/** @format */
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import Image from "react-bootstrap/Image";
import "./Navbar.css";
import { OuterSidebar } from "../../../Mod/Modal";
import { NotificationToast } from "../../../Canvas/Canvases";
import { defaultProfileIcon } from "../../../assets";
import CreateChat from "../../../Pages/Chat/CreateChat";
import { useSelector } from "react-redux";
import { userProfile } from "../../../store/authSlice";
import { fetchPaitentName } from "../../../utils/utils";
import { getApi, getObjectUrlFromDownloadUrl } from "../../../Repository/Apis";
import { getAllData } from "../../../admin/MainComponent/api";
import Typewriter from "typewriter-effect";

const Navbar = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [showA, setShowA] = useState(false);
  const [query, setQuery] = useState("");
  const [users, setUsers] = useState({});
  const [show, setShow] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const toggleShowA = () => setShowA(!showA);
  const [isChat, setIsChat] = useState(false);
  const [notifications, setNotifications] = useState({});
  const [mainNotifications, setMainNotifications] = useState({});
  const ProfileDetails = useSelector(userProfile);
  const getAllNotifications = () => {
    // getAllData("admin/allNotification").then((res) => {
    //   const todayNotifications = res.data.data?.filter(
    //     (i) =>{ 
    //       return new Date(`${i?.date} ${i?.time}`).toDateString() === new Date().toDateString()
    //     }
    //   );

    //   setNotifications(todayNotifications?.slice(0, 2));
    //   const newData = todayNotifications?.filter(
    //     (i) => i?.title && i.title.trim() !== ""
    //   );
    //   const newDate2 = newData?.map((i) => i?.body);
    //   if (newDate2 && newDate2?.length > 0) {
    //     const lastData = newDate2[newDate2.length - 1];
    //     setMainNotifications(lastData);
    //   }
    // });

    getAllData("admin/allNotification").then((res) => {
      const today = new Date().setHours(0, 0, 0, 0); // Setting today's date without time
      const todayAndFutureNotifications = res.data.data?.filter((i) => {
        const notificationDate = new Date(`${i?.date} ${i?.time}`).setHours(0, 0, 0, 0);
        return notificationDate >= today; // Include today and future dates
      });
    
      setNotifications(todayAndFutureNotifications?.slice(0, 2));
      
      const newData = todayAndFutureNotifications?.filter(
        (i) => i?.title && i.title.trim() !== ""
      );
      
      const newDate2 = newData?.map((i) => i?.title);
      if (newDate2 && newDate2?.length > 0) {
        const lastData = newDate2[newDate2.length - 1];
        setMainNotifications(lastData);
      }
    });
    
  };


  const notify = () => {
    setShowA(!showA);
  };

  const debouncedSetQuery = (term) => {
    clearTimeout(debouncedSetQuery.timeoutId);
    debouncedSetQuery.timeoutId = setTimeout(() => {
      setQuery(term);
    }, 500);
  };

  const fetchHandler = useCallback(() => {
    getApi({
      url: `employee/getPatient?limit=100&page=1&searchQuery=${query}`,
      setResponse: setUsers,
    });
  }, [query]);

  useEffect(() => {
    if (query) {
      fetchHandler();
      setShow(true);
    }
  }, [query, fetchHandler]);

  useEffect(() => {
    ProfileDetails?.userType==="Admin"?
    getAllNotifications():
      getApi({
      url: `employee/allNotification`,
      setResponse: setNotifications,
    });
  }, []);

  useEffect(() => {
    if (searchActive) {
      setShow(true);
    }
  }, [searchActive]);

  const showSearch = show === true && users?.data?.docs?.length > 0;
  const searchContainerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setShow(false);
        setSearchActive(false);
      }
    };

    document.documentElement.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.documentElement.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <OuterSidebar show={open} handleClose={() => setOpen(false)} />
      <NotificationToast
        setShow={setShowA}
        show={showA}
        notifications={notifications}
        setNotifications={setNotifications}
        handleClose={toggleShowA}
      />

      {show && <div id="nav-cover"></div>}

      <div className={"Main_Nav"}>
        <p className="Employee_title">
          {/* <strong className="text-uppercase"> */}
            Welcome Back {ProfileDetails && fetchPaitentName(ProfileDetails)}! 
          {/* </strong>  */}
          {" "}
          How can we help you today?
          <div>
          {mainNotifications?.length > 0 && (
            <Typewriter
              options={{
                strings: `${mainNotifications}`,
                autoStart: true,
                loop: true,
              }}
            />
          )}
        </div>
        </p>
        <div className="content">
          {ProfileDetails?.profilePic ? (
            <img
              src={getObjectUrlFromDownloadUrl(ProfileDetails?.profilePic) }
              alt=""
              className="profile_img"
              onClick={() => navigate("/profile")}
            />
          ) : (
            <img
              src={defaultProfileIcon}
              alt=""
              className="profile_img"
              onClick={() => navigate("/profile")}
            />
          )}

          {ProfileDetails?.adminId?.permissionChat === true && (
            <Image
              src="/Navbar/chat.png"
              className="navbar-notify-image"
              onClick={() => navigate(ProfileDetails.userType ==="Employee" ?  "/chat" :"/chatPatient")}
            />
          )}

          <Image
            src={notifications?.data?.length > 0 ? "/notify.svg" : "/nonotify.png"}
            className="navbar-notify-image"
            onClick={notify}
          />

          <i className="fa-solid fa-bars" onClick={() => setOpen(!open)}></i>
        </div>
      </div>
    </>
  );
};

export default Navbar;
