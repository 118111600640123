import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { LoginSlice, userProfile, isAuthenticated } from '../store/authSlice';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { showNotification } from '../Repository/Apis';

const useAxiosInterceptor = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => {        
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401 && error.response.data.message !=="You are not allowed to perform updated on this document") {
          localStorage.removeItem('token');
          navigate('/');
        }

        return Promise.reject(error);
      }
    );
    // Cleanup the interceptor when the component unmounts
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [navigate]);
};
const useTitle = (title) => {
  const documentDefined = typeof document !== 'undefined';
  const originalTitle = useRef(documentDefined ? document.title : "OASIS NOTES");

  useEffect(() => {
    if (!documentDefined) return;

    // Use a fallback title if `title` is undefined
    const newTitle = title || "OASIS NOTES";

    // Update the title only if it has changed
    if (document.title !== newTitle) {
      document.title = newTitle;
    }

    // Reset title on unmount
    return () => {
      document.title = originalTitle.current;
    };
  }, [title]);
};

const ProtectedRoute = ({ element: Component, allowedRoles }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);
  const token = localStorage.getItem("token");
  const isLoggedIn = useSelector(isAuthenticated);
  const location = useLocation();
  const tokenUserType = JSON.parse(localStorage.getItem("user"))?.userType
  const profile =  useSelector(userProfile);  
  const userType =  useSelector(userProfile)?.userType;  
  useAxiosInterceptor()
  useTitle(profile?.companyName);
  useEffect(() => {
    const fetchProfile = async () => {
      
      let endpoint;
      // eslint-disable-next-line default-case
      switch (tokenUserType||userType) {
        case 'Admin':
          endpoint = `${process.env.React_App_Baseurl}Admin/getProfile`;
          break;
        case 'Employee':
          endpoint = `${process.env.React_App_Baseurl}employee/getProfile`;
          break;
        case 'Patient':
          endpoint = `${process.env.React_App_Baseurl}Patient/getProfile`;
          break;
        case 'Guardian':
          endpoint = `${process.env.React_App_Baseurl}Patient/getProfile`;
          break;
      }
      
      setLoading(true);
      try {
        const res = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });        
        const data = {
          profile: { accessToken: token, data: res?.data?.data },
          timeStamp: Date.now(),
        };
        setResponse(data);
        dispatch(LoginSlice(data));
      } catch (e) {
        setResponse(e?.response?.data);
      } finally {
        setLoading(false);
      }
    };
    
    if (token) {
      fetchProfile(token);
    } else {
      
      navigate("/")
      setLoading(false);
    }
  }, [token, dispatch, navigate, userType,tokenUserType]);

  useEffect(() => {
    if (loading) return;
    if (!token || response?.message === 'Your account has been temporarily suspended. Kindly, contact your admin.') {
      navigate("/");
      showNotification("danger",response?.message)
    } else if (isLoggedIn && location.pathname === "/") {
      // eslint-disable-next-line default-case
      switch (tokenUserType||userType) {
        case 'Admin':
          navigate("/dashboard/homepage");
          break;
          case 'Employee':
          navigate("/Home");
          break;
          case 'Patient':       
          navigate("/patient_panel");
          break;
          case 'Guardian':       
          navigate("/patient_panel");
          break;
      }
    }
  }, [loading, token, response, isLoggedIn, navigate, location.pathname, userType,tokenUserType]);

  return allowedRoles.includes(tokenUserType||userType)||location.pathname === "/change-password" ? Component : <Navigate to="/unauthorized" />;
};
export default ProtectedRoute