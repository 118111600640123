/** @format */
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { arrayUnion, doc, getDoc, updateDoc } from "firebase/firestore";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { auth, db } from "../firebase";
import { userProfile } from "../store/authSlice";
import moment from "moment";
import { parse, format, isValid } from "date-fns";




// mm-dd-yy
export const DateforInput = (date) => {
  if (date) {
    const formattedDate = new Date(date)?.toISOString()?.split("T")[0];
    return formattedDate;
  }
};

export const TimeForInput = (date) => {
  if (date) {
    const formattedTime = new Date(date)?.toISOString()?.split("T")[1]?.split(".")[0];
    return formattedTime;
  }
};
// print report
export const PrintThis = (handlePrint) => {
  var elements = document.getElementsByClassName("hidePrint");

  for (var i = 0; i < elements.length; i++) {
    elements[i].style.display = "none";
  }

  handlePrint();
  setTimeout(() => {
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.display = "block";
    }
  }, 1000);
};

// Return Signature
export const signatureFormat = ({ sign, date, time, hoursFormat, withText = true, style = {} }) => {
 
const formattedDateTime = getFormattedDateTime(date,hoursFormat)

  return withText === true
    ? sign && (
      <p className="mb-0 mt-0 align-items-center text-lg-end" id="Print23" style={{ fontWeight: "light", fontSize: "14px", width: '100%', ...style }}>
        {/* Digitally Sign by {sign} {date && formatDateToMMDDYYYY(date)} {hoursFormat && time && convertTimeFormat(time, hoursFormat)}{" "} */}
        Digitally Sign by {sign} {formattedDateTime}{" "}
      </p>
    )
    : sign && (
      <p className="mb-0 mt-0 align-items-center text-lg-end" style={{ fontWeight: "light", fontSize: "14px", width: '100%', ...style }}>
        {/* {sign} {date && formatDateToMMDDYYYY(date)} {hoursFormat && time && convertTimeFormat(time, hoursFormat)}{" "} */}
        {sign} {formattedDateTime}{" "}
      </p>
    );
};



// Utility function for formatting date and time
export const getFormattedDateTime = (date, hoursFormat ) => {

  
  let formattedDateTime = "";
  let updatedFormatString 
 // Adjust formatString for consistency
 if (hoursFormat === "h:mm A") {
  updatedFormatString = "h:mm a";
} else {
  updatedFormatString = hoursFormat;
}

  try {
    if (date) {
      const parsedDate = new Date(date);

      if (isValid(parsedDate)) {
        // Format date and time
        const formattedDate = format(parsedDate, "MM/dd/yyyy");
        const formattedTime = format(
          parsedDate,
          updatedFormatString
        );

        formattedDateTime = `${formattedDate} ${formattedTime}`;
      } else {
        throw new Error("Invalid date format");
      }
    }
  } catch (error) {
    console.error("Error formatting date or time:", error);
  }

  return formattedDateTime;
};
// Chat Firebase

export const SignInFirebase = async ({ payload }) => {
  // const dispatch = useDispatch();
  const { email, _id: password } = payload;
  return signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      return Promise.resolve(userCredential.user)
    })
    .catch((error) => {
      if (
        error.code === "auth/user-not-found" ||
        error.code === "auth/invalid-credential"
      ) {
        return createUserWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            return Promise.resolve(userCredential.user)
          })
          .catch((error) => {
            console.error("Error creating account:", error);
          });
      } else {
        console.error("Error signing in:", error);
      }
    });
};

// Debouncing Set Query
export const debouncedSetQuery = ({ term, setQuery }) => {
  clearTimeout(debouncedSetQuery.timeoutId);
  debouncedSetQuery.timeoutId = setTimeout(() => {
    setQuery(term);
  }, 500);
};

export const fetchPaitentName = (i) => {
  if (i?.firstName || i?.lastName) {
    return `${i?.firstName} ${i?.lastName}`;
  } else {
    return i?.fullName;
  }
};

export const checkMultiValues = (setState, state, value) => {
  if (state?.includes(value))
    setState((pre) => pre?.replace(value, ""));
  else
    setState((pre) => pre?.replaceAll(undefined, "") + value)
}

// Bold syntax
export const BoldSyntax = (text) => {
  return <span className="fw-bold"> {text} </span>;
};

// Remove Memeber from groupChat
export const removeMemberGroup = async ({ memberIdToRemove, documentId }) => {
  const docRef = doc(
    db,
    process.env.React_App_Firebase_Group_CollectionName,
    documentId
  );
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const documentData = docSnap.data();
    const updatedMembers = documentData.members.filter(
      (member) => member._id !== memberIdToRemove
    );
    const updatedMembersId = documentData.membersId.filter(
      (id) => id !== memberIdToRemove
    );

    try {
      await updateDoc(docRef, {
        members: updatedMembers,
        membersId: updatedMembersId,
      });
    } catch (error) {
      console.error("Error removing member from document:", documentId, error);
    }
  } else {
    console.warn("No such document!");
  }
};

// Add Member in Group
export const addMemberToDocument = async ({ documentId, newMembers }) => {
  try {
    const docRef = doc(
      db,
      process.env.React_App_Firebase_Group_CollectionName,
      documentId
    );
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const documentData = docSnap.data();

      try {
        const updatedMembers = arrayUnion(...newMembers);
        const updatedMemberIds = newMembers.map((member) => member._id);

        await updateDoc(docRef, {
          members: updatedMembers,
          membersId: arrayUnion(...updatedMemberIds),
        });

      } catch (error) {
        console.error(
          "Error adding new members to document:",
          documentId,
          error
        );
      }
    } else {
      console.warn("No such document!");
    }
  } catch (error) {
    console.error("Error fetching document:", error);
  }
};

// get Random Color
export const getRandomColor = (() => {
  let previousColorIndex = -1;

  return (colors) => {
    let randomIndex = Math.floor(Math.random() * colors.length);
    while (randomIndex === previousColorIndex) {
      randomIndex = Math.floor(Math.random() * colors.length);
    }
    previousColorIndex = randomIndex;
    return colors[randomIndex];
  };
})();

// className getter
export const getClassName = (value, key) => {
  if (key === value) {
    return "active";
  } else {
    return "";
  }
};


export const AddSignature = ({ show, setValue, setTime, setDate }) => {
  const capturedTimeRef = useRef(""); // Ref to store the captured time
  const hasLoggedRef = useRef(false); // Ref to avoid continuous console logging

  const profileDetails = useSelector(userProfile);
  const employeeName = profileDetails && fetchPaitentName(profileDetails);
  const employeePosition = profileDetails?.position;
  const employeeSign = `${employeeName} ${profileDetails.userType === "Admin" ? "Admin" : employeePosition
    }`;
  const hoursFormat = profileDetails?.hoursFormat === "24" ? "HH:mm" : "h:mm A";

  useEffect(() => {
    if (show && !capturedTimeRef.current) {
      let updatedformatString
      if (hoursFormat === "h:mm A") {
        updatedformatString = "h:mm a";
      } else {
        updatedformatString = hoursFormat;
      }
      const currentDate = new Date();
      // const hours = currentDate.getHours();
      // const minutes = currentDate.getMinutes();
      // const seconds = currentDate.getSeconds();
      // const capturedTime = `${hours.toString().padStart(2, "0")}:${minutes
      //   .toString()
      //   .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
      // capturedTimeRef.current = capturedTime;

      const capturedTime = format(currentDate, updatedformatString);

      capturedTimeRef.current = capturedTime;

      setValue(employeeSign);
      // setTime(capturedTime);
      setDate(currentDate);

      // Log only once for debugging
      if (!hasLoggedRef.current) {
        hasLoggedRef.current = true;
      }
    }
  }, [show, employeeSign, setValue, setTime, setDate]);

  return null;
};


export const AddSignatureForTable = ({ show, setValue, setTime, setDate, setShow }) => {
  const capturedTimeRef = useRef(""); // Ref to store the captured time
  const hasLoggedRef = useRef(false); // Ref to avoid continuous console logging

  const profileDetails = useSelector(userProfile);
  const employeeName = profileDetails && fetchPaitentName(profileDetails);
  const employeePosition = profileDetails?.position;
  const employeeSign = `${employeeName} ${profileDetails.userType === "Admin" ? "Admin" : employeePosition
    }`;
  const hoursFormat = profileDetails?.hoursFormat === "24" ? "HH:mm" : "h:mm A";


  useEffect(() => {
    if (show) {
      // capturedTimeRef.current = "";
      let updatedformatString
      if (hoursFormat === "h:mm A") {
        updatedformatString = "h:mm a";
      } else {
        updatedformatString = hoursFormat;
      }
      const currentDate = new Date();
      // const hours = currentDate.getHours();
      // const minutes = currentDate.getMinutes();
      // const seconds = currentDate.getSeconds();
      // const capturedTime = `${hours.toString().padStart(2, "0")}:${minutes
      //   .toString()
      //   .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

      const capturedTime = format(currentDate, updatedformatString);

      capturedTimeRef.current = capturedTime;
      setValue(employeeSign);
      // setTime(capturedTime);
      setDate(currentDate);

      // Log only once for debugging
      if (!hasLoggedRef.current) {
        hasLoggedRef.current = true;
      }
    }
    setShow(false)
  }, [show, employeeSign, setValue, setTime, setDate]);

  return null;
};




export const BaseUrl = process.env.React_App_Baseurl;
export const AdminBaseUrl = process.env.React_App_BaseurlAdmin;

export const AppAuth = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
};

// export function formatDateToMMDDYYYY(dateInput) {
//   if (!dateInput) return "";

//   const date = new Date(dateInput);
//   if (isNaN(date.getTime())) return "";

//   const month = String(date.getMonth() + 1).padStart(2, '0');
//   const day = String(date.getDate()).padStart(2, '0');
//   const year = date.getFullYear();

//   return `${month}/${day}/${year}`;
// }

export function formatDateToMMDDYYYY(dateInput) {
  // Return an empty string if no input is provided
  if (!dateInput) return "";

  if (dateInput instanceof Date) {
    const date = moment(dateInput);
    return date.format("MM/DD/YYYY");
  }

  const date = moment?.utc(dateInput);

  if (!date.isValid()) return "";

  return date.format("MM/DD/YYYY");
}

export function formatDateWithoutUTCHandleToMMDDYYYY(dateInput) {
  if (!dateInput) return "";
  const date = moment(dateInput);
  if (!date.isValid()) return "";
  return date.format("MM/DD/YYYY");
}

export const formatDateTimeSheet = (dateInput) => {
  if (!dateInput) return "";

  // Ensure the date is treated as UTC
  const date = new Date(`${dateInput}T00:00:00Z`);
  if (isNaN(date?.getTime())) return "";

  const month = String(date?.getUTCMonth() + 1)?.padStart(2, "0");
  const day = String(date?.getUTCDate())?.padStart(2, "0");
  const year = date?.getUTCFullYear();
  return `${month}/${day}/${year}`;
};


/**
 * Converts a saved time string to a moment object based on the target hours format.
 * @param {string} timeString - The saved time string to convert.
 * @param {string} targetFormat - The target format ('HH:mm' for 24-hour, 'h:mm a' for 12-hour).
 * @returns {moment|null} - The formatted moment object, or null if the input is invalid.
 */
export const getFormattedValueTime = (timeString, targetFormat) => {
  if (!timeString) return null; // Handle null or undefined time

  const inputFormat = /[APM]/i.test(timeString) ? "h:mm A" : "HH:mm"; // Determine input format
  const momentObj = moment(timeString, inputFormat); // Parse the input time

  return momentObj.isValid() ? momentObj : null; // Return the moment object if valid
};


/**
 * Converts a time string to a specified format.
 * @param {string} time - The time string to convert (e.g., "15:53").
 * @param {string} formatString - The target format string (e.g., "HH:mm" or "h:mm A").
 * @returns {string} - The formatted time string.
 */

export const convertTimeFormat = (time, formatString) => {
  let updatedFormatString;

  // Adjust formatString for consistency
  if (formatString === "h:mm A") {
    updatedFormatString = "h:mm a";
  } else {
    updatedFormatString = formatString;
  }

  let parsedTime;

  try {
    if (typeof time === "string" &&
      (time.includes("am") || time.includes("Am") || time.includes("AM") ||
        time.includes("pm") || time.includes("Pm") || time.includes("PM"))) {
      parsedTime = parse(time, "h:mm a", new Date());
    } else if (typeof time === "string" && time.includes("T")) {
      parsedTime = new Date(time);
    } else if (typeof time === "string" && time.includes(":") && time.split(":").length === 3) {
      parsedTime = parse(time, "HH:mm:ss", new Date());
    } else if (typeof time === "string" && time.includes(":")) {
      parsedTime = parse(time, "HH:mm", new Date());
    } else if (time instanceof Date) {
      parsedTime = time;
    } else {
      throw new Error("Unsupported time format");
    }

    if (!isValid(parsedTime)) {
      throw new Error("Invalid time format");
    }

    return format(parsedTime, updatedFormatString);
  } catch (error) {
    console.error("Error converting time format:", error);
    return time; 
  }
};


export const parseTimeStringToDate = (timeInput) => {
  if (!timeInput) return null;

  // If the input is already a Date object, return it as-is
  if (timeInput instanceof Date) {
    return timeInput;
  }

  // Handle string input
  if (typeof timeInput === "string") {
    const regex12Hour = /^(\d{1,2}):(\d{2})\s*(am|pm|Am|Pm)$/i; // Matches 12-hour format (e.g., "12:02 am", "11:00 pm")
    const regex24Hour = /^(\d{1,2}):(\d{2})$/; // Matches 24-hour format (e.g., "02:02", "23:59")

    // Handle ISO 8601 format
    if (timeInput.includes("T")) {
      const parsedDate = new Date(timeInput);
      if (!isNaN(parsedDate)) {
        return parsedDate; // Return the parsed ISO date
      } else {
        throw new Error("Invalid ISO time format");
      }
    }

    // Check for 12-hour format
    const match12Hour = timeInput.match(regex12Hour);
    if (match12Hour) {
      // eslint-disable-next-line no-unused-vars
      let [_, hourStr, minuteStr, meridiem] = match12Hour;
      let hours = parseInt(hourStr, 10);
      const minutes = parseInt(minuteStr, 10);

      if ((meridiem.toLowerCase() === "pm" || meridiem.toLowerCase() === "Pm") && hours !== 12) {
        hours += 12; // Convert PM hours to 24-hour format
      } else if ((meridiem.toLowerCase() === "am" || meridiem.toLowerCase() === "Am") && hours === 12) {
        hours = 0; // Convert midnight to 0 hours
      }

      const now = new Date();
      return new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);
    }

    // Check for 24-hour format
    const match24Hour = timeInput.match(regex24Hour);
    if (match24Hour) {
      // eslint-disable-next-line no-unused-vars
      const [_, hourStr, minuteStr] = match24Hour;
      const hours = parseInt(hourStr, 10);
      const minutes = parseInt(minuteStr, 10);

      const now = new Date();
      return new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);
    }

    // If no valid format is detected, throw an error
    throw new Error("Invalid time input format");
  }

  throw new Error("Invalid time input type");
};



