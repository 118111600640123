/** @format */

import React, { useEffect, useState } from "react";
import HOC from "../../Layout/Outer/HOC";
import {
  getObjectUrlFromDownloadUrl,
  showNotification,
  UpdateProfile,
} from "../../Repository/Apis";
import { Container, Row, Col, Form, Card, Modal } from "react-bootstrap";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { userProfile } from "../../store/authSlice";
import { defaultProfileIcon } from "../../assets/index";
import UpdatePassword from "./UpdatePassword";
import StaffFacility from "./StaffFacility";
const InputGroup = ({
  label,
  setValue,
  value,
  placeholder,
  type = "text",
  pattern,
}) => {
  return (
    <div className="Input_Group mb-3">
      <Form.Label className="fw-bold">{label}</Form.Label>
      <Form.Control
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        pattern={type === "tel" ? pattern : undefined}
      ></Form.Control>
    </div>
  );
};

const Profile = () => {
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [address, setAddress] = useState("");
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [tempImageUrl, setTempImageUrl] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middle, setMiddle] = useState("");
  const ProfileDetails = useSelector(userProfile);
  const [logoImage, setLogoImage] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [format, setFormat] = useState(false);
  const [showFacility, setShowFacility] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (ProfileDetails) {
      setEmail(ProfileDetails?.email);
      setMobileNumber(ProfileDetails?.mobileNumber);
      setAddress(ProfileDetails?.address);
      setFirstName(ProfileDetails?.firstName);
      setLastName(ProfileDetails?.lastName);
      setMiddle(ProfileDetails?.middle);
      setGender(ProfileDetails?.gender);
      setCompanyName(ProfileDetails?.companyName)
      setLogoImage(ProfileDetails?.logo)
      setFormat(ProfileDetails?.hoursFormat)
    }
  }, [ProfileDetails]);

  const fd = new FormData();

  function appendIfPresent(formValue, value) {
    if (value) {
      fd.append(formValue, value);
    }
  }
  appendIfPresent("image", image);
  appendIfPresent("firstName", firstName);
  appendIfPresent("lastName", lastName);
  appendIfPresent("middleName", middle);
  appendIfPresent("gender", gender);
  appendIfPresent("mobileNumber", mobileNumber);
  appendIfPresent("email", email);
  appendIfPresent("address", address);
  appendIfPresent("logo", logoImage);
  appendIfPresent("companyName", companyName);
  if(ProfileDetails?.userType === "Admin"){
    appendIfPresent("hoursFormat", format);
  }

  const handleSubmit = async (e) => {
    let updateUrl;
    if (ProfileDetails?.userType === "Admin") {
      updateUrl = `/admin/updateProfile`;
    } else if (ProfileDetails?.userType === "Employee") {
      updateUrl = `employee/updateProfile`;
    } else if (ProfileDetails.userType === "Patient") {
      updateUrl = `Patient/updateProfile`;
    }
    e.preventDefault();
    dispatch(
      UpdateProfile({
        payload: fd,
        setLoading,
        url: updateUrl,
        isAdmin: ProfileDetails?.userType === "Admin",
      })
    );
  };

  const selectImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      const validFileTypes = ["image/jpeg", "image/png"];
      if (!validFileTypes.includes(file.type)) {
        showNotification({
          message: "Please select a valid JPG or PNG image.",
          type: "default",
        });
        return;
      }
      const maxSizeInBytes = 2 * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        showNotification({
          message: "File size should be less than 2 MB.",
          type: "default",
        });
        return;
      }
      setImage(file);
    }
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImageUrl(imageUrl);
    }
  };
  const selectImageLogo = (e) => {
    const file = e.target.files[0];
    if (file) {
      const validFileTypes = ["image/jpeg", "image/png"];
      if (!validFileTypes.includes(file.type)) {
        showNotification({
          message: "Please select a valid JPG or PNG image.",
          type: "default",
        });
        return;
      }
      const maxSizeInBytes = 2 * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        showNotification({
          message: "File size should be less than 2 MB.",
          type: "default",
        });
        return;
      }
      setLogoImage(file);
    }
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setTempImageUrl(imageUrl);
    }
  };

  const ChooseFile = () => {
    const target = document.getElementById("file");
    target.click();
  };
  const ChooseFileLogo = () => {
    const target = document.getElementById("logoFile");
    target.click();
  };

  return (
    <>
      <UpdatePassword show={modalShow} onHide={() => setModalShow(false)} />
      <StaffFacility
        show={showFacility}
        onHide={() => setShowFacility(false)}
      />

      <Container>
        <div className="page-title-bar mb-3">
          <Row>
            <Col xs={12} md={4} xl={3}></Col>
            <Col xs={12} md={4} xl={6}>
              <p className="heading">Profile</p>
            </Col>
            <Col xs={12} md={4} xl={3}>
              {ProfileDetails.userType === "Admin" && (
                <div className="d-flex justify-content-end">
                  <button
                    onClick={() => setShowFacility(true)}
                    className="upload-btn "
                  >
                    {" "}
                    Add Facility{" "}
                  </button>
                </div>
              )}
            </Col>
          </Row>
        </div>
        <Card body className="mb-3">
          <Row>
            <Col xs={12} lg={4} xl={3}>
              <div className="profile-section">
                <div className="main">
                  <div className="contain">
                    <Card body className="mb-3">
                      <img
                        src={
                          imageUrl
                            ? imageUrl
                            : ProfileDetails?.profilePic
                            ? getObjectUrlFromDownloadUrl(
                                ProfileDetails?.profilePic
                              )
                            : defaultProfileIcon
                        }
                        alt=""
                        className="cursor-pointer"
                      />
                      <h6 className="mt-3 mb-0 text-center fw-bold">
                        {" "}
                        {ProfileDetails?.firstName ?? ""}{" "}
                        {ProfileDetails?.middleName ?? ""}{" "}
                        {ProfileDetails?.lastName ?? ""}{" "}
                      </h6>
                      <div className="upload-profile">
                        <button
                          onClick={() => ChooseFile()}
                          className="upload-btn"
                        >
                          {" "}
                          Change Profile{" "}
                        </button>
                        <div className="Input_Group">
                          <input
                            type={"file"}
                            className="d-none"
                            id="file"
                            onChange={(e) => selectImage(e)}
                          />
                        </div>
                      </div>
                    </Card>
                    <Card body className="mb-3 relative">
                      {ProfileDetails.userType === "Admin" && (
                        <img
                          src={
                            tempImageUrl
                              ? tempImageUrl
                              : getObjectUrlFromDownloadUrl(
                                  ProfileDetails?.logo
                                )
                          }
                          alt=""
                          style={{
                            width: "200px",
                            objectFit: "contain",
                            borderRadius: "0",
                            background: "white",
                          }}
                        />
                      )}
                      <h6 className="mt-3 mb-0 text-center fw-bold">
                        {companyName}
                      </h6>
                      <div className="upload-profile">
                        {ProfileDetails.userType === "Admin" && (
                          <button
                            onClick={() => ChooseFileLogo()}
                            className="upload-btn mb-2"
                          >
                            {" "}
                            Change Logo{" "}
                          </button>
                        )}
                        {ProfileDetails.userType === "Admin" && (
                          <div className="Input_Group">
                            <input
                              type={"file"}
                              className="d-none"
                              id="logoFile"
                              accept=".jpg,.png"
                              onChange={(e) => selectImageLogo(e)}
                            />
                          </div>
                        )}
                      </div>
                      <div className="d-flex justify-content-center">
                        <button
                          onClick={() => setModalShow(true)}
                          className="upload-btn "
                        >
                          {" "}
                          Change Password{" "}
                        </button>
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} lg={8} xl={9}>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} lg={4}>
                    <InputGroup
                      label={"First Name"}
                      setValue={setFirstName}
                      value={firstName}
                      type="text"
                      placeholder="First Name"
                    />
                  </Col>
                  <Col xs={12} lg={4}>
                    <InputGroup
                      label={"Middle Name"}
                      setValue={setMiddle}
                      value={middle}
                      type="text"
                      placeholder="Middle Name"
                    />
                  </Col>
                  <Col xs={12} lg={4}>
                    <InputGroup
                      label={"Last Name"}
                      setValue={setLastName}
                      value={lastName}
                      type="text"
                      placeholder="Last Name"
                    />
                  </Col>
                  <Col xs={12} lg={6}>
                    <Form.Label className="fw-bold">Gender</Form.Label>
                    <Form.Select
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Transgender">Transgender</option>
                    </Form.Select>
                  </Col>
                  <Col xs={12} lg={6}>
                    <Form.Label className="fw-bold">Time Format</Form.Label>
                    <Form.Select
                    disabled={ProfileDetails?.userType !== "Admin"}
                      value={format}
                      onChange={(e) => setFormat(e.target.value)}
                    >
                      <option value="">Select Format</option>
                      <option value="24">24 hrs</option>
                      <option value="12">12 hrs</option>
                    </Form.Select>
                  </Col>
                  <Col xs={12} lg={6}>
                  <Form.Group className="mt-3">

                    <InputGroup
                      label={"Mobile Number"}
                      setValue={setMobileNumber}
                      value={mobileNumber}
                      type="tel"
                      placeholder="Mobile Number"
                      // pattern={"[0-9]{10}"}
                      />
                      </Form.Group>
                  </Col>
                  <Col xs={12} lg={6}>
                  <Form.Group className="mt-3">


                    <InputGroup
                      label={"Email Address"}
                      setValue={setEmail}
                      value={email}
                      type="email"
                      placeholder="email@gmail.com"
                      />
                      </Form.Group>
                  </Col>
                  <Col xs={12} lg={12}>
                    <InputGroup
                      label={"Address"}
                      setValue={setAddress}
                      value={address}
                      type="text"
                    />
                  </Col>
                  <Col xs={12} lg={12}>
                    {ProfileDetails.userType === "Admin" && (
                      <InputGroup
                        label={"Company Name"}
                        setValue={setCompanyName}
                        value={companyName}
                        type="text"
                      />
                    )}
                  </Col>
                </Row>
                <Row className="text-center">
                  <Col xs={12} lg={12}>
                    <button className="employee_create_btn mt-3">
                      {" "}
                      {loading ? <ClipLoader color="#fff" /> : "Update"}{" "}
                    </button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card>
      </Container>
    </>
  );
};

export default HOC({ Wcomponenet: Profile });
